import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Articles = () => (
  <Layout>
    <SEO title="Articles" />
    <h1>Articles</h1>
    <article>
      <h1>Beginner's Mind</h1>
      <q>
        In the beginner's mind there are many possibilities, but in the expert's
        there are few.
      </q>
      <cite>Zen Mind, Beginner's Mind</cite>

      <p>
        People say that practicing Zen is difficult, but there is a
        misunderstanding as to why. It is not difficult because it is hard to
        sit in the cross-legged position, or to attain enlightenment. It is
        difficult because it is hard to keep our mind pure and our practice pure
        in its fundamental sense. The Zen school developed in many ways after it
        was established in China, but at the same time, it became more and more
        impure. But I do not want to talk about Chinese Zen or the history of
        Zen. I am interested in helping you keep your practice from becoming
        impure.
      </p>
      <p>
        In Japan we have the phrase <em>shoshin</em>, which means "beginner's
        mind." The goal of practice is always to keep our beginner's mind.
        Suppose you recite the Prajna Paramita Sutra only once. It might be a
        very good recitation. But what would happen to you if you recited it
        twice, three times, four times, or more? You might easily lose your
        original attitude towards it. The same thing will happen in your other
        Zen practices. For a while you will keep your beginner's mind, but if
        you continue to practice one, two, three years or more, although you may
        improve some, you are liable to lose the limitless meaning of original
        mind.
      </p>

      <p>
        For Zen students the most important think is not to be dualistic. Our
        "original mind" includes everything within itself. It is always rich and
        sufficient within itself. You should not lose your self-sufficient state
        of mind. This does not mean a closed mind, but actually an empty minds
        and a ready mind. If your mind is empty, it is always ready for
        anything; it is open to everything. In the beginner's mind there are
        many possibilities; in the expert's mind there are few.
      </p>

      <p>
        If you discriminate too much, you limit yourself. If you are too
        demanding or too greedy, you mind is not rich and self-sufficient. If we
        lose our original self-sufficient mind, we will lost all precepts. When
        your mind becomes demanding, when you long for something, you will end
        up violating your own precepts: not to tell lies, not to steal, not to
        kill, not to be immoral, and so forth. If you keep your original mind,
        the precepts will keep themselves.
      </p>

      <p>
        In the beginner's mind there is no thought, "I have attained something."
        All self-centered thoughts limit our vast mind. When we have no thought
        of achievement, no thought of self, we are true beginners. Then we can
        really learn something. The beginner's mind is the mind of compassion.
        When our mind is compassionate, it is boundless. Dogen-zenji, the
        founder of our school, always emphasized how important it is to resume
        our boundless original mind. Then we are always true to ourselves, in
        sympathy with all beings, and can actually practice.
      </p>

      <p>
        So the most difficult thing is always to keep your beginner's mind.
        There is no need to have a deep understanding of Zen. Even though you
        read much Zen literature, you must read each sentence with a fresh mind.
        You should not say, "I know what Zen is," or "I have attained
        enlightenment." This is also the real secret of the arts: always be a
        beginner. Be very very careful about this point. If you start to
        practice zazen, you will begin to appreciate your beginner's mind. It is
        the secret to Zen practice.
      </p>
    </article>
  </Layout>
)

export default Articles
